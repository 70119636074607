
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300&display=swap');

*{
  margin: 0;
  padding: 0;
}
body{
  font-family: 'Poppins', sans-serif;
}
img{
  max-width: 100%;
}
a{
  text-decoration: none;
}
.form-control:focus{
  box-shadow: 0 0 0 0.1rem rgb(134 196 67 / 45%);
}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.1rem rgb(134 196 67 / 42%);
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #86c443;
  border-color: #86c443;
}
.btn:hover {
  color: #fff;
  background-color: #86c443;
  border-color: #86c443;
}

.btn-primary.focus, .btn-primary:focus {
  color: #fff;
  background-color: #86c443;
  border-color: #86c443;
  box-shadow: 0 0 0 0.1rem rgb(134 196 67 / 45%);
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl{
  padding: 0 70px;
}

#App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 8px 0;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 6;
  transition: all .3s;
}

.App-link {
  color: #fff;
}

.OurCnt {
  line-height: 0;
}

#App-header .OurCnt a {
  font-size: 16px;
  color: #fff;
  line-height: 28px;
  text-transform: uppercase;
  cursor: pointer;
  font-family: 'Lato', sans-serif;
  font-weight: 800;
  padding-bottom: 2px;
  vertical-align: middle;
  position: relative;
}

#App-header a:hover {
  color: #fff;
}
.Digipanda_Contact{
  position: relative;
}
a.Digipanda_Contact:before {
  content: "";
  display: inline-block;
  height: 14px;
  width: 1px;
  background: #ddd;
  position: relative;
  top: 1px;
  left: -18px;
}

.Digipanda_Contact:hover{
  color: #deb00a !important;
}

#App-header a.GetStarted{
  background: #fff;
  padding: 10px 16px 10px 43px;
  border-radius: 25px;
  font-size: 14px;
  font-weight: 700;
  color: #234890;
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
  margin: 0 25px;
  font-family: poppins,sans-serif;
}

#App-header a.GetStarted:before{
  position: absolute;
  display: block;
  content: '';
  width: 20px;
  height: 20px;
  top: 14px;
  left: 18px;
  z-index: 2;
  pointer-events: none;
  background-color: none;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 0 #234890;
}
#App-header a.GetStarted:after{
  position: absolute;
  display: block;
  content: '';
  width: 20px;
  height: 20px;
  top: 14px;
  left: 18px;
  z-index: 2;
  pointer-events: none;
  background-color: transparent;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: inset 0 0 0 5px #234890;
  box-shadow: inset 0 0 0 5px #234890;
}
#App-header a.GetStarted:hover:after{
  -webkit-box-shadow: inset 0 0 0 5px #11316f;
  box-shadow: inset 0 0 0 5px #11316f;
  -webkit-transition: all 0s ease-in-out .2s;
  -o-transition: all 0s ease-in-out .2s;
  transition: all 0s ease-in-out .2s;
}
#App-header a.GetStarted:hover:before{
  -webkit-box-shadow: 0 0 0 30px transparent;
  box-shadow: 0 0 0 30px transparent;
}
#App-header a.GetStarted:hover:before {
  -webkit-transition: all .5s ease-in-out .2s;
  -o-transition: all .5s ease-in-out .2s;
  transition: all .5s ease-in-out .2s;
  -webkit-box-shadow: 0 0 0 30px transparent;
  box-shadow: 0 0 0 30px transparent;
  -webkit-box-shadow: inset 0 0 0 5px #11316f;
  box-shadow: inset 0 0 0 5px #11316f;
}
.menu-notification a.font3bold span.black {
  color: #fff;
}
.bg-light{
  background: transparent !important;
}
.ToggleBTN .nav-menu {
  width: 50px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  margin-top: 6px;
}
.ToggleBTN .nav-menu span {
  display: inline-block;
  width: 32px;
  height: 5px;
  background: #fff;
  float: left;
  margin: 1px auto 7px;
  position: relative;
  transition: all .3s;
}
.ToggleBTN .nav-menu span.right-ar {
  left: 7px;
}
.ToggleBTN .nav-menu:hover span.right-ar {
  left: 0;
}
.CloseNavMenu span {
  display: inline-block;
  width: 32px;
  height: 5px;
  float: left;
  margin: 1px auto 7px;
  position: relative;
  transition: all .3s;
}
.CloseNavMenu span.right-ar:before, .CloseNavMenu span.right-ar:after {
  content: "";
  width: 32px;
  height: 5px;
  background: #fff;
  float: left;
  margin: 1px auto 7px;
  position: absolute;
  transition: all .3s;
  display: inline-block;
  top: 0;
  left: 0;
  right: 0;
}
.CloseNavMenu span.right-ar:before {
  opacity: 1;
  transform: rotate(
45deg
);
}
.CloseNavMenu span.right-ar:before, .CloseNavMenu span.right-ar:after {
  content: "";
  width: 32px;
  height: 5px;
  background: #fff;
  float: left;
  margin: 1px auto 7px;
  position: absolute;
  transition: all .3s;
  display: inline-block;
  top: 0;
  left: 0;
  right: 0;
}
.CloseNavMenu span.right-ar:after {
  opacity: 1;
  transform: rotate(
-45deg
);
}
.navbar-light .navbar-nav .nav-item {
    margin: 0 15px;
}

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff;
  text-transform: none;
  font-family: lato,sans-serif;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  line-height: 50px;
  display: inline-block;
  padding: 0;
  position: relative;
}
.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link{
  color: #fff;
}
.navbar-light .navbar-nav .nav-item .nav-link:after {
  content: "";
  display: inline-block;
  width: 0;
  background: #fff;
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: all .2s;
  height: 2px;
  border: 0px;
}
.navbar-light .navbar-nav .nav-item .nav-link:hover:after {
  width: 100%;
}

.dropdown-toggle::after{
  display: none;
}

.dropdown-menu {
  min-width: 270px;
  background: #fff;
  border-radius: 0;
  border: 1px solid #e5e5e5;
  border-top: 0;
  text-align: left;
  display: none;
  padding: 0;
}
.dropdown-menu a {
  position: relative;
}
.dropdown-menu a {
  color: #234890;
  padding: 0 12px 2px 15px;
  display: inline-block;
  font-weight: 500;
  width: 100%;
  border-top: 1px solid #e5e5e5;
  font-size: 14px;
  text-decoration: none;
  line-height: 42px;
  text-transform: none;
  font-family: lato,sans-serif;
}
.dropdown-item:active{
  background: transparent;
  border: none;
  color: #234890;
}
.dropdown-menu a:hover{
  color: #11316f !important;
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover{
  color: #fff;
}

.dropdown-item:focus, .dropdown-item:hover{
  background-color: transparent;
}

header.fixedHeader {
  background: #234890 !important;
}

.social-icons a {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 0 0 0 5px;
  opacity: 1;
  color: #fff;
  border-radius: 50%;
}
.social-icons a:hover{
  color: #deb00a !important;
}
.toggle_nav .nav-item {
  width: 100%;
  display: block;
  padding: 0;
  transition: all .3s;
}
.toggle_nav .nav-item  a {
  color: #fff;
  font-size: 27px;
  line-height: 1;
  text-transform: uppercase;
  font-family: poppins,sans-serif;
  font-weight: 700;
  padding: 10px 0;
  width: 100%;
  display: inline-block;
  text-decoration: none;
}
.toggle_nav .dropdown-menu a {
  color: #234890;
  padding: 0 12px 2px 15px;
  display: inline-block;
  font-weight: 500;
  width: 100%;
  border-top: 1px solid #e5e5e5;
  font-size: 14px;
  text-decoration: none;
  line-height: 42px;
  text-transform: none;
  font-family: lato,sans-serif;
}
.toggle_nav .nav-item  a:hover {
  color: #deb00a !important;
}
.toggle_nav .dropdown-menu{
  margin-top: 0px !important;
  min-width: 270px;
  max-width: 290px;
}
/*.toggle_nav .dropdown-menu a.dropdown-item{
  font-size: 14px;
  line-height: 3;
  text-transform: uppercase;
  font-weight: 600;
  padding: 2px 0;
  width: 100%;
  transition: all .3s;
  font-family: poppins,sans-serif;
  display: inline-block;
  color: #234890;
  text-decoration: none;
  padding: 0 15px;
}*/

.HereoBanner{
  background:#022a5e url(../images/home-banner.jpg) no-repeat center center/cover;
  background-attachment: fixed;
  position: relative;
  height: 100vh;
}
.HereoBanner:before {
  content: "";
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url(../images/overlay-pattern.png) rgba(1,42,94,.05);
  z-index: 1;
  height: 100%;
}
.Banner_Content{
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translate(0,-50%);
  margin: 0 auto;
  left: 0;
  right: auto;
  z-index: 2;
  padding: 43px 0 0 0;
  max-width: 1000px;
}

.Banner_Content h1 {
  color: #fff;
  text-transform: uppercase;
  font-size: 82px;
  line-height: 82px;
  margin-top: 40px;
  margin-bottom: 35px;
  font-weight: 800;
  text-indent: 0;
  letter-spacing: -4px;
}
.Banner_Content h1 span{
  color: #deb00a;
}
.Banner_Content p {
  font-size: 24px;
  color: #fff;
  font-family: lato,sans-serif;
  line-height: 30px;
  font-weight: 600;
  padding-top: 25px;
}
.red-border {
  background: #234890;
  height: 1px;
  display: inline-block;
  width: 100px;
  border: 1px solid #234890;
}
.Banner_Content .red-btn {
  border-radius: 40px;
  margin: 25px auto 0;
  transition: all .3s;
  background: #234890;
  color: #fff;
  padding: 12px 32px;
  text-decoration: none;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  font-size: 18px;
  font-family: poppins,sans-serif;
}
.Banner_Content .red-btn:hover {
  background: #11316f;
}
.MainNavbarNav{
  display: none;
  height: 100%;
  position: fixed;
  width: 100%;
  top: 0px;
  right: 0px;
  z-index: 9;
  background: #234890;
}

.MainNavbarNav.fadeIn{
  -webkit-animation: fadeIn .5s ease-in 1 forwards;
  animation: fadeIn .5s ease-in 1 forwards;
  opacity: 0;
}
.navbar-light .navbar-brand{
  position: relative;
  z-index: 99;
}

@-webkit-keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

a.CloseNavMenu {
    position: absolute;
    right: 85px;
    top: 35px;
    cursor: pointer;
}

.MainNavigation {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
}
.pTop{
  padding-top: 100px;
}

.MainNavbarNav h1 {
  color: #fff;
  text-transform: uppercase;
  font-size: 72px;
  line-height: 72px;
  margin-top: 60px;
  margin-bottom: 35px;
  font-weight: 800;
  text-indent: 0;
  letter-spacing: -4px;
}
.MainNavbarNav h1 span {
  color: #deb00a;
}
.MainNavbarNav .red-btn {
  border-radius: 40px;
  margin: 10px auto 0;
  transition: all .3s;
  background: #082661;
  color: #fff;
  padding: 12px 32px;
  text-decoration: none;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  font-size: 15px;
  font-family: poppins,sans-serif;
  box-shadow: 0 0 19px 7px rgb(35 72 144);
  width: auto;
}
.MainNavbarNav .red-btn:hover {
  background: #1a2a4a;
}
.office-sec {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: left;
  color: #fff;
  border-top: 1px solid rgba(255,255,255,.15);
}
.office-sec .col {
  padding: 20px 2%;
}
.office-sec .col {
  border: 1px solid rgba(255,255,255,.15);
  margin-left: -1px;
  border-top: 0;
  display: flex;
  align-items: center;
}
.office-sec .col:nth-child(1) {
  border-left: 0;
  text-align: center;
}
.office-sec .col:nth-child(5) {
  border-right: 0;
  text-align: center;
}
.office-sec .title {
  position: relative;
  top: 50%;
  transform: translate(0,-50%);
}
.office-sec .title {
  font-size: 45px;
  font-weight: 700;
  color: #fff;
  margin: 0 auto 10px;
  font-family: poppins,sans-serif;
  text-transform: uppercase;
}
.office-sec p {
  color: #fff;
  font-size: 15px;
  padding: 0;
  margin: 0;
}

.padding{
  padding: 80px 0 35px;
}

.HomeServices h2{
  font-size: 38px;
  color: #222;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1.05;
  letter-spacing: -1.5px;
  font-family: poppins,sans-serif;
  margin: 10px auto 35px;
}

.HomeServices h2 span{
  color: #deb00a;
}

.HomeServices p{
  color: #758495;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
}

.same-btn{
  border-radius: 40px;
  margin: 25px auto 0;
  transition: all .3s;
  background: #234890;
  color: #fff;
  padding: 15px 32px;
  text-decoration: none;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  font-size: 15px;
  font-family: poppins,sans-serif;
}
.same-btn:hover {
  background: #11316f;
}

.Service_Col{
  padding-bottom: 45px;
  position: relative;
}
.Service_Col span {
  display: block;
  text-align: center;
}
.HomeServices h3{
  font-weight: 700;
  text-transform: uppercase;
  font-family: poppins,sans-serif;
  margin: 25px auto 20px;
  color: #023575;
  letter-spacing: 0;
  text-decoration: none;
  line-height: 1.3;
  font-size: 18px;
  text-align: center;
}

.Service_Col p{
  font-size: 14px;
}

.transform-row{
  background: #234890;
}
.transform-sec{
  padding: 80px 0;
  border-bottom: 1px solid rgba(255,255,255,.15);
  margin-top: 30px;
}
.transform-sec h2 {
  text-align: center;
  width: 100%;
  font-size:54px;
  font-weight: 800;
  letter-spacing: -2.5px;
  line-height: 1;
  margin: 0 auto -10px;
  font-family: poppins,sans-serif;
  padding-top: 10px;
  color: #fff;
}

.transform-sec h2 span {
  color: #deb00a;
}
.p-15{
  padding: 0 15px;
}
.certified-sec {
  text-align: center;
  font-size: 15px;
}
.certified-sec .sm-col {
  padding: 30px 0;
  color: #fff;
  font-family: poppins,sans-serif;
  border-right: 1px solid rgba(255,255,255,.15);
}
.counter {
  font-size: 38px;
  font-weight: 600;
  padding-left: 15px;
}
.counter::after {
  content: "+";
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
  top: -3px;
  left: 3px;
  color: #deb00a;
}
.certified-sec .sm-col p {
  font-size: 13px;
  font-weight: 500;
  line-height: 2px;
}
.certified-sec .sm-col h4 {
  font-size: 20px;
  font-weight: 700;
  padding: 12px 0 10px;
  line-height: 1.2;
}
.certified-sec .sm-col h4 span{
  color: #deb00a;
}
.certified-sec .sm-col:last-child {
  border-right: 0;
}
.flex-column.nav a {
  font-size: 15px;
  color: #758495;
  text-decoration: none;
  vertical-align: middle;
  line-height: 12px;
  transition: all .1s ease-in-out;
  display: inline-block;
  width: calc(100% - 15px);
  float: left;
  font-family: lato,sans-serif;
  position: relative;
  padding: 8px 15px;
}
.flex-column.nav a:before {
  content: '';
  position: absolute;
  top: 12px;
  left: 0;
  background: #666;
  width: 6px;
  height: 6px;
  border-radius: 50px;
}
.flex-column.nav a:hover {
  color: #deb00a !important;
}
.href_link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.brands{
  text-align: center;
  padding:10px 0 35px 0;
}
.brands .list-group{
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 230px);
}
.brands .list-group-item{
  background: transparent;border: none;
  padding: 0px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 3.5% 0 0;
}
.brands .list-group-item img{
  width: auto;
}
.brands a.same-btn{
  background: #082661;
}
.brands a.same-btn:hover{
  background: #081e48;
}
.column-box {
  margin: 0 auto 10px auto;
  padding:0 15px;
}
.column-box .sm-cols {
  border: 1px solid #eaeaea;
  margin-left: -1px;
  margin-bottom: -1px;
  padding: 0;
}
.column-box p {
  font-size: 13px;
  color: #758495;
  font-family: poppins,sans-serif;
  font-weight: 500;
  padding: 40px 10px;
  line-height: 17px;
  text-transform: uppercase;
}
.testimonial-col {
  background: url(../images/customer-bg3.jpg) no-repeat center center/cover;
  height: 510px;
  position: relative;
}
.testimonial-col .testimonial-slider {
  max-width: 475px;
  margin: 0 auto;
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
}
.testimonial-col .item {
  padding: 30px 40px 80px;
  background: #fff;
  height: 250px;
}
.testimonial-col h4 {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: poppins,sans-serif;
  float: left;
  width: 100%;
  margin: 0 auto 15px;
  color: #222;
}
.testimonial-col h4 span{
  color: #deb00a;
}
.testimonial-col p {
  font-weight: 400;
  font-size: 16px;
  color: #758495;
  font-style: normal;
}
.testimonial-col .owl-dots {
  position: absolute;
  bottom: 20px;
  right: 30px;
}
.testimonial-col .owl-dots .owl-dot span {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #ccc;
  margin: 0 3px;
  vertical-align: middle;
  border-radius: 50%;
}
.testimonial-col .owl-dots .owl-dot.active span {
  background: #ef323a;
}
.CaseStudy h2{
  font-size: 38px;
  color: #222;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1.05;
  letter-spacing: -1.5px;
  font-family: poppins,sans-serif;
  margin: 10px auto 35px;
}

.CaseStudy h2 span {
  color: #deb00a;
}

.inner-cols {
  width: 100%;
  height: 65vh;
  background: #ccc;
  text-align: center;
  position: relative;
}
.banner1 {
  background: url(../images/banner1.jpg) no-repeat center center/cover;
}
.banner2 {
  background: url(../images/banner2.jpg) no-repeat center center/cover;
}
.banner3 {
  background: url(../images/banner3.jpg) no-repeat center center/cover;
}
.banner4 {
  background: url(../images/banner4.jpg) no-repeat center center/cover;
}
.banner-col-content {
  position: absolute;
  bottom: 25px;
  width: 100%;
}
.col-logo {
  width: auto;
  display: inline-block;
  position: relative;
  margin: 0 auto 20px;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  max-width: 100%;
  position: relative;
}
.inner-cols .para {
  position: relative;
  color: #fff;
  display: none;
  opacity: 0;
  padding: 0 30px;
  line-height: 1.4;
  font-size: 16px;
}
.banner-col-content .red-btn {
  display: none;
  border-radius: 40px;
  margin: 25px auto 0;
  transition: all .3s;
  background: #082661;
  color: #fff;
  padding: 12px 32px;
  text-decoration: none;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 15px;
  font-family: poppins,sans-serif;
}
.banner-col-content .red-btn:hover {
  background: #071d48;
}
.inner-cols::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.1);
  left: 0;
  right: 0;
}
.inner-cols:before {
  background: -moz-linear-gradient(top,rgba(0,0,0,0) 0%,rgba(0,0,0,0) 25%,rgba(0,0,0,0.61) 100%);
  background: -webkit-linear-gradient(top,rgba(0,0,0,0) 0%,rgba(0,0,0,0) 25%,rgba(0,0,0,0.61) 100%);
  background: linear-gradient(to bottom,rgba(0,0,0,0) 0%,rgba(0,0,0,0) 25%,rgba(0,0,0,0.61) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000',endColorstr='#9c000000',GradientType=0 );
}
.inner-cols:hover:before {
  background: #234890;
  transition: all .2s;
}
.inner-cols:hover .banner-col-content {
  width: 100%;
  top: 50%;
  bottom: auto;
  transform: translate(0,-50%);
}
.inner-cols:hover .para, .inner-cols:hover .red-btn {
  display: inline-block;
  opacity: 1;
}
.inner-cols:hover .para, .inner-cols:hover .red-btn {
  display: inline-block;
  opacity: 1;
}
.CaseStudy-slider .owl-nav {
  display: block !important;
  position: absolute;
  top: -95px;
  right: 92px;
}

.CaseStudy-slider .owl-nav  span {
  display: inline-block;
  width: 40px;
  height: 40px;
  font-size: 0;
  margin: 0 3px;
  float: left;
  opacity: 1;
  outline: 0;
  border-radius: 50px;
  box-shadow: none;
}
.CaseStudy-slider .owl-nav .owl-prev span{
  background:#023575 url(../images/arrow-left.png) no-repeat 0 0;
  background-position: center;
}
.CaseStudy-slider .owl-nav .owl-next span{
  background:#023575 url(../images/arrow-right.png) no-repeat 0 0;
  background-position: center;
}
.CaseStudy-slider.owl-theme .owl-nav [class*='owl-']:hover{
  background: none;
}
.CaseStudy-slider .owl-nav .owl-prev span:hover {
  background:#deb00a url(../images/arrow-left.png) no-repeat 0 0;
  background-position: center;
}
.CaseStudy-slider .owl-nav .owl-next span:hover{
  background:#deb00a url(../images/arrow-right.png) no-repeat 0 0;
  background-position: center;
}
.Footer{
  padding: 20px 0 70px;
}
.Footer h4{
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: poppins,sans-serif;
  margin: 5px auto 10px;
  color: #023575;
  margin: 0 auto 20px;
  display: inline-block;
}
.Footer p {
  margin: 0 auto 25px;
  color: #758495;
  font-size: 15px;
  font-family: lato,sans-serif;
}
.Footer .footer-head {
  color: #ef323a;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: poppins,sans-serif;
  margin: 0 auto 10px;
  display: inline-block;
}
.phone-contact {
  margin: 10px auto 20px;
}
.Footer .phone-contact{
  color: #758495;
  font-size: 15px;
  font-family: lato,sans-serif;
}
.Footer .phone-contact a {
  font-size: 19px;
  display: block;
  font-weight: bold;
  color: #023575;
  font-family: poppins,sans-serif;
  line-height: 1.5;
  letter-spacing: -2px;
}
.rowfull, .rowfull a{
  color: #758495;
  font-size: 15px;
  font-family: lato,sans-serif;
}
.Footer ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.Footer ul a{
  float: left;
  width: 100%;
  line-height: 2;
  color: #758495;
  font-size: 15px;
  transition: all .3s;
  font-family: lato,sans-serif;
}
.Footer ul a:hover{
  color: #deb00a;
}
.menu-listing {
  padding-top: 35px;
}
.Copyright{
  background: #234890;
  padding: 20px 0;
  font-size: 13px;
  color: #fff;
  text-transform: uppercase;
}
.Copyright p{
  margin-bottom: 0;
}
.social-icons {
  text-align: right;
}
.social-icons a{
  font-size:19px;
  height: auto;
}

.contact {
  background: #022a5e url(../images/contact.jpg) no-repeat 0px -135px/auto;
  background-attachment: fixed;
  background-size: 100% auto;
  color: #fff;
  padding: 90px 0 120px;
  position: relative;
  font-size: 18px;
  height: 500px;
}
.contact h1{
  font-size: 85px;
  letter-spacing: -4.5px;
  margin-bottom: 10px;
}
.HereoBanner.Webdevelopment .Banner_Content{
  max-width: 100%;
  width: 100%;
}
.HereoBanner.Webdevelopment{
  background-color: #f0f2f5;
  background-image: linear-gradient(
180deg
, #6c7d92 13%, #eee 13%);
  padding: 120px 0 0;
  min-height: 80vh;
  height: 700px;
}
.HereoBanner.Webdevelopment:before{
  display: none;
}
.banner-right-content p {
  font-size: 20px;
  color: #758495;
  font-weight: 400;
  line-height: 1.6;
  padding-top: 0;
}

.Consultingback{
  background: #022a5e url(../images/consulting.jpg) no-repeat 0px -135px/auto;
  background-attachment: fixed;
  background-size: 100% auto;
  color: #fff;
  padding: 90px 0 120px;
  position: relative;
  font-size: 18px;
  height: 500px;
}



.Consultingback h2{
  font-size: 76px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: -3px;
  font-family: poppins,sans-serif;
  margin: 10px 0 10px;
  text-align: left;
  color: #fff;
}
.gdback{
  background: #022a5e url(../images/bigdata.jpg) no-repeat 0px -135px/auto;
}
h2.pagehead-title{
  font-size: 76px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: -3px;
  font-family: poppins,sans-serif;
  margin: 10px 0 10px;
  text-align: left;
  color: #fff;
}

.iotback{
  background: #022a5e url(../images/iot.jpg) no-repeat 0px -135px/auto;
}
.blockchain-back{
  background: #022a5e url(../images/blockchain.jpg) no-repeat 0px -135px/auto;
}
.AI-back{
  background: #022a5e url(../images/artificial-intelligence.jpg);
  background-size: cover;
}
.CluodC-back{
  background: #022a5e url(../images/cloud-computing.jpg);
  background-size: cover;
}
.OutsourcingBack{
  background: #022a5e url(../images/outsourcing.jpg);
  background-size: cover;
}
.technoloBack{
  background: #022a5e url(../images/technology.jpg);
  background-size: cover;
}
.HDBack{
  background: #022a5e url(../images/hire-developers.jpg);
  background-size: cover;
}
.automotive-back{
  background: #022a5e url(../images/industries.jpg) no-repeat 0px -135px/auto;
  background-attachment: fixed;
  background-size: 100% auto;
  color: #fff;
  padding: 90px 0 120px;
  position: relative;
  font-size: 18px;
  height: 500px;
}

.case-study-back{
  background: #022a5e url(../images/case-studies.jpg) no-repeat 0px -135px/auto;
  background-attachment: fixed;
  background-size: cover;
  color: #fff;
  padding: 90px 0 120px;
  position: relative;
  font-size: 18px;
  height: 500px;
}

.TestiBack{
  background: #022a5e url(../images/testimonials.jpg);
  background-attachment: fixed;
  background-size: cover;
  color: #fff;
  padding: 90px 0 120px;
  position: relative;
  font-size: 18px;
  height: 500px;
}

.industry-back{
  background: #022a5e url(../images/industries.jpg) no-repeat 0px -135px/auto;
  background-attachment: fixed;
  background-size: 100% auto;
  color: #fff;
  padding: 90px 0 120px;
  position: relative;
  font-size: 18px;
  height: 500px;
}

.PrivacyBack{
  background: #022a5e url(../images/privacy.jpg);
  background-attachment: fixed;
  background-size: cover;
}

.web{
  background: #022a5e url(../images/web.jpg);
  background-attachment: fixed;
  background-size: cover;
}

.blog-back{
  background: #022a5e url(../images/web.jpg);
  background-attachment: fixed;
  background-size: cover;
  height: 450px;
}

.ADAcomplice {
  background: #022a5e url(../images/ADAcomplice.jpg);
  background-attachment: fixed;
  background-size: cover;
  color: #fff;
  padding: 90px 0 120px;
  position: relative;
  font-size: 18px;
  height: 100vh;
}


.Pricingbg{
  background: #022a5e url(../images/pricing.jpg);
  background-attachment: fixed;
  background-size: 100% auto;
  color: #fff;
  padding: 90px 0 120px;
  position: relative;
  font-size: 18px;
  height: 500px;
}


.HereoBanner.Webdevelopment.digipanda-portfolio {
  background: linear-gradient( 133deg , rgb(54 57 49) 1%,rgb(68 72 61) 44.5%,rgb(45 50 37) 44.5%,rgb(45 50 37) 100%);
}
.HereoBanner.Webdevelopment.digipanda-portfolio1{
  background: #912c56;
  padding: 80px 0 0;
  height: 600px;
}
.section h2{
  font-size: 76px;
  padding-bottom: 15px;
}
.section p {
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 23px;
  font-weight: normal;
}

.HereoBanner.Webdevelopment.digipanda-portfolio2 {
  background: linear-gradient( 133deg , rgb(237 51 99) 1%,rgb(241 45 96) 44.5%,rgb(223 38 52) 44.5%,rgb(224 37 51) 100%);
  padding: 80px 0 0;
  height: 650px;
}

.HereoBanner.Webdevelopment.digipanda-portfolio3{
  background: #2d6e53;
  padding: 80px 0 0;
  height: 650px;
}
.HereoBanner.Webdevelopment.digipanda-portfolio3 .section .right-img-sec{
  bottom: -190px;
}
.HereoBanner.Webdevelopment.digipanda-portfolio4 {
  background: linear-gradient( 154.2deg , #fd3545 0%,#f33949 50%,#e41828 50%,#e72736 99%);
  padding: 80px 0 0;
  height: 600px;
}
.blog-post:nth-child(1) {
  border: none;
  padding: 0;
  margin: 0;
}
.blog-post {
  border-top: 1px solid #eee;
  padding-top: 55px;
  margin-top: 55px;
}
.blog_content p a{
  position: relative;
  padding-left: 20px;
}
.blog_content p a:before {
  content: '';
  width: 10px;
  height: 10px;
  background: #deb00a;
  position: absolute;
  top: 22px;
  left: 0px;
  border-radius: 50px;
}
.inquirebox{
  padding: 30px 20px;
  background: #022a5e;
  color: #fff;
  display: inline-block;
  line-height: normal;
  width: 100%;
  margin-top: 30px;
}
.leftContent {
  float: left;
  width: calc(100% - 245px);
  margin-right: 10px;
  font-family: 'Poppins', sans-serif;
}
.smallTxt {
  font-size: 14px;
  margin: 0 auto;
  font-weight: 400;
  line-height: 13px;
  margin-top: 10px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.capTxt {
  font-size: 21px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 24px;
  margin-top: 6px;
}
.inquirebox .contact-bttn {
  display: inline-block;
  margin-top: 6px;
}

.makingCus_{
  background: #022a5d;
  padding: 40px;
}
.makingCus_ h2 {
  color: #fff;
  line-height: 46px;
}
.makingCus_ h2 span{
  color: #009ada;
}
.PartnerdigiPanda{
  background: #eaeef2;
  text-align: center;
  padding: 60px 0;
}
.PartnerdigiPanda h3{
  width: 100%;
  font-size: 44px;
  font-weight: 800;
  letter-spacing: -2.5px;
  line-height: 1;
  font-family: poppins,sans-serif;
  padding-bottom: 30px;
  color: #222;
  text-transform: uppercase;
}
.PartnerdigiPanda h3 span{
  color: #deb00a;
}
.PartnerdigiPanda p {
  font-style: italic;
  color: #758495;
  transition: all .3s;
  font-size: 15px;
  line-height: 1.9;
  font-weight: 400;
}
.digital-content h3{
  font-size: 38px;
  color: #222;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1.05;
  letter-spacing: -1.5px;
  font-family: poppins,sans-serif;
  margin: 10px auto 35px;
}
.digital-content h3 span{
  color: #deb00a;
}
.digital-content h4{
  font-weight: 700;
  text-transform: uppercase;
  font-family: poppins,sans-serif;
  margin: 25px auto 20px;
  color: #023575;
  letter-spacing: 0;
  text-decoration: none;
  line-height: 1.3;
  font-size: 18px;
}
.digital-content p{
  color: #758495;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
}

.SEObanneR{
  background: #022a5e url(../images/seo-bg.jpg);
  background-attachment: fixed;
  background-size: cover;
  padding: 90px 0 120px;
  height: 100vh;
}

.SEObanneR h2.pagehead-title{
  font-size: 72px;
}
.whyUs p{
  font-style: normal;
}
.whyUs h4 {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bolder;
  margin-top: 30px;
}