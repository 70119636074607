
.Webdevelopment .left-img {
    left: -150px;
    max-width: 115%;
    top: -10px;
    position: relative;
}
.Webdevelopment .left-img {
    padding-left: 35px;
    padding-top: 90px;
}
.banner-right-content {
    padding: 0px 15px 0 0;
    position: relative;
    left: -40px;
}

.banner-right-content h2 {
    color: #023575;
    font-size: 76px;
    font-weight: 800;
    text-transform: uppercase;
    line-height: 1;
    letter-spacing: -3px;
    font-family: poppins,sans-serif;
    margin: 10px 0 35px;
    text-align: left;
}

.banner-right-content h2 span{
    color: #deb00a;
}
.heart-img {
    text-align: right;
    padding: 0;
    margin-bottom: -85px;
    width: auto;
}
.heart-img img{
    width: auto;
}
.brands.technology_brands {
    text-align: center;
    padding: 35px 0 35px 0 !important;
}
.brands.technology_brands .list-group{
    width: 100% !important;
}
.brands.technology_brands .list-group-item{
    border-right: 1px solid rgba(255,255,255,.2) !important;
    margin:  0 !important;
    padding: 0 15px !important;
}
.brands.technology_brands .list-group-item:last-child{
    border-right: none !important;
}
.brands.technology_brands .list-group-item img{
    max-width: 180px;
}
h2{
    width: 100%;
    font-size: 54px;
    font-weight: 800;
    letter-spacing: -2.5px;
    line-height: 1;
    margin: 0 auto -10px;
    font-family: poppins,sans-serif;
    padding-top: 10px;
    color: #222;
    text-transform: uppercase;
}

h2 span {
    color: #deb00a;
}
.process-sec h2{
    text-align: center;
}

.process-sec p{
    display: inline-block;
    float: left;
    width: 100%;
    text-align: center;
    color: #758495;
    font-size: 20px;
    font-family: lato,sans-serif;
    font-weight: 500;
    line-height: 1.3;
    margin-top: 35px;
}
ul.hire-team-process {
    width: 100%;
    padding: 60px 0 20px;
    float: left;
    list-style: none;
}
ul.hire-team-process li {
    width: 25%;
    float: left;
    padding: 0 18px;
    text-align: center;
    position: relative;
    transition: all .5s;
}
ul.hire-team-process li:first-child {
    border-left: none;
}
.dashed-circle {
    width: 200px;
    height: 200px;
    border: 2px dotted #c5ced5;
    border-radius: 50%;
    display: inline-block;
}
.project-requirment {
    background: url(../images/postequirements.png) no-repeat center center/85px;
}
ul.hire-team-process .figcaption {
    margin: 40px auto 0;
}
ul.hire-team-process li .figcaption h4 {
    padding: 0 10px;
    margin: 0 auto 20px;
    font-size: 18px;
    line-height: 1.3;
    font-weight: 700;
    text-transform: uppercase;
    font-family: poppins,sans-serif;
    letter-spacing: 0;
    text-decoration: none;
}
ul.hire-team-process li .figcaption p.sub-title-area {
    font-size: 16px;
    color: #758495;
    font-weight: 400;
    line-height: 1.4;
    margin-top: 0px;
}
ul.hire-team-process li:after {
    position: absolute;
    content: '';
    height: 1px;
    top:21%;
    right: -17.25%;
    width: 35%;
    border-bottom: 2px dotted #c5ced5;
}
ul.hire-team-process li:last-child:after{
    display: none;
}
.discuss-project {
    background: url(../images/discussproject.png) no-repeat 50% 47%/90px;
}
.terms-n-timelines {
    background: url(../images/engagementterms.png) no-repeat center center/auto;
}
.get-started {
    background: url(../images/securepayment.png) no-repeat 50% 60%/95px;
}
.Industry_Bussness{
    border-bottom: 1px solid #e5e5e5;
    border-top: 1px solid #e5e5e5;
    float: left;
    width: 100%;
    background: #e9edf3;
    padding: 100px 0;
    overflow: hidden;
}
.Industry_Bussness h3{
    font-weight: 800;
    text-transform: uppercase;
    line-height: 1.05;
    letter-spacing: -1.5px;
    font-family: poppins,sans-serif;
    margin: 10px auto 35px;
    font-size: 38px;
}
.Industry_Bussness h3 span{
    color: #deb00a;
}
.Industry_Bussness p{
    color: #758495;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.6;
}
.Commitment_area p {
    display: inline-block;
    float: left;
    width: 100%;
    text-align: center;
    color: #758495;
    font-size: 20px;
    font-family: lato,sans-serif;
    font-weight: 500;
    padding: 0 8%;
    line-height: 1.3;
    margin-top: 35px;
}
.Commitment_area.clearfix {
    clear: both;
}
.guarantee-row{
    margin-top: 60px;
}
.guarantee-row .grnt-cols, .partners-row .grnt-cols {
    padding: 0 17px;
    border-right: 1px solid #e5e5e5;
}
.guarantee-row .grnt-cols:first-child {
    padding-left: 0;
}
.guarantee-row .grnt-cols img {
    max-width: 70px;
    max-height: 60px;
}
.guarantee-row h5 {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: poppins,sans-serif;
    margin: 25px auto 10px;
    color: #222;
    letter-spacing: 0;
    text-decoration: none;
}
.guarantee-row p {
    font-size: 16px;
    color: #758495;
    font-weight: 400;
    line-height: 1.4;
    margin: 0;
    text-align: left;
    padding: 0;
}

.guarantee-row .grnt-cols:last-child {
    padding-right: 0;
    border: 0;
}

.SuccessArea{
    padding: 90px 0;
    font-size: 18px;
    border-top: 1px solid #e5e5e5;
    background: #e9edf3;
    text-align: center;
}
.SuccessArea p{
    margin: 40px auto 0;
    color: #023575;
    font-size: 25px;
    font-weight: 800;
    line-height: 1.2;
    font-family: poppins,sans-serif;
    letter-spacing: -.5px;
}
.achivements{
    width: 100%;
    float: left;
    background: #022a5e;
    text-align: center;
    border-top: 1px solid #e5e5e5;
    padding: 90px 0;
    font-size: 18px;
}
.achivements .col-lg-3 {
    padding: 0 41px;
    border-left: 1px solid #35557e;
}
.achivements .col-lg-3:first-child {
    border-left: 0;
}
.achivements img {
    max-width: 115px;
    margin: 0 auto 20px;
}
.awards p {
    font-size: 18px;
    text-transform: uppercase;
    color: #fff;
    line-height: 1.3;
    font-weight: 700;
    text-transform: uppercase;
    font-family: poppins,sans-serif;
}
.awards span {
    color: #0099da;
    font-size: 14px;
    margin: 1px auto 10px;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 700;
    font-family: poppins,sans-serif;
}
.padding.pb-50{
    padding-bottom:90px;
}

.banner-left-img .app-dev img {
    max-width: 520px;
    position: relative;
    top: 3px;
    left: 0;
}
.Webdevelopment .left-img.app-dev{
    left: 0;
    bottom: 0;
    top: 19px;
}
.Industry_Bussness .RightImg, .Industry_Bussness .RightImgSft{
    position: relative;
}
.Industry_Bussness .RightImg img{
    max-width: 650px;
    right: -55px;
    bottom: -100px;
    position: absolute;
}
.perform_content{
    padding: 100px 0;
}
.Industry_Bussness .RightImgSft img {
    max-width: 100%;
    right: -55px;
    bottom: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.consul_hire{
    padding: 75px 15px 80px;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    background: #eaeef2;
}

.consul_hire .consul_col{
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: -1px;
    background: #fff;
    border: 1px solid #e5e5e5;
    padding: 0;
    font-size: 16px;
    color: #758495;
    font-weight: 400;
    line-height: 1.4;
    border-right: 0;
    transition: all .3s;
}
.consultant-content{
    padding: 35px 30px 50px;
    color: #758495;
    text-decoration: none;
    transition: all .3s;
    float: left;
    width: 100%;
    height: 100%;
}
.consultant-content h3 {
    font-family: poppins,sans-serif;
    margin: 0 auto 15px;
    color: #023575;
    font-weight: 700;
    text-transform: uppercase;
    transition: all .3s;
    font-size: 18px;
    line-height: 1.2;
}
.consultant-content p.exp {
    color: #758495;
    font-weight: 400;
    transition: all .3s;
    font-size: 16px;
    font-family: lato,sans-serif;
    line-height: 1.5;
}
.consultant-content:hover h3 {
    color: #deb00a;
}
.consultant-content:hover p {
    color: #fff;
}
.consultant-content:hover {
    background: #234890;
    color: #fff;
}
.Webdevelopment.QualityTesting .left-img {
  max-width: 100%;
  float: left;
  left: 0;
  position: relative;
  top: -18px;
  right: 0;
}
.Webdevelopment.QualityTesting .left-img img{
  position: relative;
  left: -183px;
  bottom: 0;
  max-width: 680px;
  float: left;
  right: 0;
}
.Commitment_area.border-bottom{
    padding-bottom: 80px;
}

.automation-col img{
    width: 100%;
    height: auto;
    max-width: 65px;
    max-height: 150px;
    transition: all .3s;
    margin-bottom: 30px;
}

.automation-col:hover img{
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
}

.RightImgindusB{
    position: relative;
}
.RightImgindusB img{
    max-width: 100%;
    right: -55px;
    bottom: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.WhatsNew{
    padding-top: 80px;
}
.WhatsNew .whatsNew-col{
    min-height: 480px;
    background-color: #bee3ff;
    background-image: url(../images/patterns.png);
    transition: .3s;
    padding: 60px 25px;
    margin: 0 .6%;
    max-width: 23.8%;
}

.WhatsNew h3 {
    font-size: 26px;
    text-transform: uppercase;
    color: #023575;
    font-family: poppins,sans-serif;
    text-align: left;
    font-weight: 800;
    margin: 0 auto 20px;
    line-height: 1.1;
    letter-spacing: -.5px;
}
.WhatsNew .border-line {
    border-top: 2px solid #023575;
    display: inline-block;
    width: 75px;
    margin: 18px auto 15px;
}
.WhatsNew p {
    color: #023575;
    font-size: 18px;
    font-family: lato,sans-serif;
    margin-bottom: 0;
}
.WhatsNew .whatsNew-col:hover {
    background: #023575;
    background-image: url(../images/patterns.png);
    color: #fff;
}
.WhatsNew .whatsNew-col:hover h3{
    color: #deb00a;
}
.WhatsNew .whatsNew-col:hover .border-line {
    border-color: #fff;
}
.WhatsNew .whatsNew-col:hover p{
    color: #fff;
}

.WhatsNew .whatsNew-col.blockchainCol{
    margin: 0 .6%;
    max-width: 32%;
}

.benifits h2{
    margin: 10px auto 23px;
}

.benifits p.small-para {
    display: inline-block;
    float: left;
    width: 100%;
    text-align: center;
    color: #758495;
    font-size: 20px;
    font-family: lato,sans-serif;
    font-weight: 500;
    margin-bottom: 55px;
}
.dedicated-teams .column-box .sm-cols {
    margin: 0;
    border-right: 0;
    border-top: 0;
    padding: 0;
}
.dedicated-teams .column-box .sm-cols:first-child {
    border-top: 1px solid #eaeaea;
}
.dedicated-teams .column-box .sm-cols:nth-child(3n+1), .dedicated-teams .column-box .sm-cols:last-child {
    border-right: 1px solid #eaeaea;
}
.sm-cols h4 {
    font-size: 22px;
    color: #222;
    background: #fff;
    font-weight: 800;
    letter-spacing: 0;
    padding: 25px 20px;
    letter-spacing: -.5px;
    line-height: 24px;
    text-transform: uppercase;
}
.sm-cols h4 span{
    color: #deb00a;
}
.column-box p {
    font-size: 13px;
    color: #758495;
    font-family: poppins,sans-serif;
    font-weight: 500;
    padding: 40px 10px;
    line-height: 17px;
    text-transform: uppercase;
}
.consultant-content{
    position: relative;
}
.consultant-content img{
    max-width: 50px;
    margin-bottom: 25px;
}
.consultant-content:hover img {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
}
.industries-content{
    background:#eaeef2;
    padding: 90px 0 0 0;
}
.industries-content .inner-box {
    padding: 0 50px;
    margin: 0 auto 90px;
    border: 1px solid #e5e5e5;
    background: #fff;
}
.left-sec {
    padding-right: 65px;
    padding-left: 10px;
    border-right: 1px solid #e5e5e5;
    margin-right: -1px;
    padding-top: 50px;
    padding-bottom: 80px;
}
.right-sec {
    padding-left: 55px;
    padding-right: 5px;
    border-left: 1px solid #e5e5e5;
    padding-top: 50px;
    padding-bottom: 80px;
}

.industries-content h3{
    margin: 50px auto 10px;
    font-size: 18px;
    color: #222;
    font-weight: 700;
    font-family: poppins,sans-serif;
    text-transform: uppercase;
    line-height: 1.5;
}
.industries-content p {
    font-size: 16px;
    color: #758495;
    line-height: 1.5;
    font-weight: 400;
    font-family: lato,sans-serif;
}
.industries-content p span{
    display: inline-block;
    width: 100%;
    margin: 10px auto 2px;
    font-weight: 600;
}
.industries-content .listings {
    font-size: 16px;
    color: #758495;
    margin: 0 auto 30px;
    padding: 0;
    list-style: none;
}
.industries-content .listings li {
    padding: 3px 0 3px 12px;
    position: relative;
    font-family: lato,sans-serif;
}
.listings li:before {
    background: #758495;
    top: 15px;
    content: "";
    display: inline-block;
    width: 5px;
    height: 5px;    
    border-radius: 50%;
    vertical-align: middle;
    position: absolute;
    left: 0;
    transform: translate(0,-50%);
}
.blue-box {
    font-size: 16px;
    font-weight: 500;
    font-family: lato,sans-serif;
    line-height: 1.3;
    color: #fff;
    background: #023575;
    padding: 25px;
}
.title-wht {
    font-weight: 700;
    font-family: poppins,sans-serif;
    font-size: 20px;
    margin: 0 auto 10px;
    text-transform: uppercase;
}
.blue-box .same-btn{
    background-color: #deb00a;
}
.blue-box .same-btn:hover{
    background-color: #af8b08;
    color: #fff;
}
.inner-cols.all-cstudy{
    margin-top: 30px;
}
.Study-customer{
    padding-bottom: 80px !important;
    margin-top: 45px;
}
.consultant-content h3 span{
    color: #deb00a;
}

.profiles_tab .accordion__button a{
    display: block;
    padding-left: 35px;
}

.profiles_tab h3{
    font-family: poppins,sans-serif;
    margin: 0 0 10px;
    color: #023575;
    font-weight: 700;
    text-transform: uppercase;
    transition: all .3s;
    font-size: 18px;
    line-height: 1.2;
}

.profiles_tab div{
    color: #758495;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;
    font-family: lato,sans-serif;
}

.profiles_tab ul li{
    line-height: 34px;
}
.accordion__button {
    display: flex;
    align-items: center;
    position: relative;
}
.accordion__button:before{
    position: absolute;
    top: 23px;
}
.accordion__button .same-btn{
    margin:0 0 0 auto;
}

.reviews a {
    color: #fff;
    display: inline-block;
    width: 100%;
    margin: 0 auto 15px;
    text-decoration: none;
    transition: all .3s;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(255,255,255,.2);
    font-weight: 400;
    line-height: 1.5;
    font-size: 17px;
    font-family: lato,sans-serif;
}

.left-sec.border-right-0{
    border-right: 0px;
}

.achivements.pdtop-bottom{
    margin: 40px 0 90px;
}

.Banner_Content.w-100{
    max-width: 100%;
    text-align: center;
}

.blog-back .pagehead-title {
    background: #fff;
    color: #234890;
    font-size: 34px;
    letter-spacing: 0;
    padding: 20px 35px;
    text-align: center;
    display: inline-block;
    width: auto;
}

.blog-back .pagehead-title span{
    color: #deb00a;
}

.blog_content h5{
    font-size: 16px;
    text-transform: uppercase;
    color: #234890;
    font-weight: bold;
}

.blog_content h5 span{
    color: #deb00a;
}

.blog_content h3 a{
    color: #234890;
    font-size: 38px;
    font-weight: 800;
    text-transform: uppercase;
    line-height: 1.05;
    letter-spacing: -1px;
    font-family: 'Poppins', sans-serif;
    margin: 30px auto 30px;
    text-decoration: none;
    display: inline-block;
}

.blog_content p a{
    color: #234890;
    font-size: 16px;
    border-top: 1px solid #ccc;
    display: block;
    padding-top: 15px;
}

.HereoBanner.Webdevelopment{
    position: relative;
}

.HereoBanner.Webdevelopment.digipanda-portfolio p{
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 23px;
}

.HereoBanner.Webdevelopment.digipanda-portfolio .left-img{
    position: relative;
    left: -35%;
    top: auto;
    width: auto;
    padding: auto;
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -o-transform: scale(0.9);
    -ms-transform: scale(0.9);
}

.HereoBanner.Webdevelopment.digipanda-portfolio .left-img img{
    max-width: initial;
}

.red-btn svg{
    font-size: 24px;
}

.HereoBanner.Webdevelopment.digipanda-portfolio .banner-right-content {
    padding: 0px 15px 0 25px;
    position: relative;
    left: 0;
}

.section{
    overflow: hidden;
    min-height: 100%;
}

.section .left-text-sec {
    padding-right: 25px;
    padding-top: 3%;
}

.section .red-btn{
    letter-spacing: -0.3px;
    font-size: 16px;
}

.banner-right-img{
    top: 50px;
    position: absolute;
    right: 0;
    bottom: 0;
}

.section .right-img-sec {
    position: absolute;
    bottom: 0;
    right: -20px;
}

.section .right-img-sec img{
    position: relative;
    right: -5.5%;
    transform: scale(0.9);
    top: 30px;
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -o-transform: scale(0.9);
    -ms-transform: scale(0.9);
    float: right;
    max-width: initial;
}

.HereoBanner.Webdevelopment.digipanda-portfolio4 .section .right-img-sec img{
    right: -30%;
    max-width: 1200px;
}

.HereoBanner.Webdevelopment.digipanda-portfolio4 .section .right-img-sec{
    margin-bottom: -120px;
}

.HereoBanner.Webdevelopment.digipanda-portfolio2 .section .right-img-sec{
    bottom: -260px;
}

.Footer {
    padding: 55px 0 70px !important;
    clear: both;
}

