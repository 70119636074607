
.contact-section {
    padding: 90px 0 110px;
    box-shadow: none;
    border: 1px solid #e5e5e5;
    background: #eaeef2;
}
.contact-form {
    background: #fff;
    border-radius: 0;
    overflow: hidden;
    position: relative;
    border: 1px solid #d8d8d8;
}
.top-contact {
    background: #fff;
    color: #023575;
    border-radius: 0 0 0 0;
    padding: 45px 20px 58px 25px;
    border-bottom: 1px solid #d8d8d8;
}
.top-contact .h2.sm {
    font-size: 28px;
    font-family: poppins,sans-serif;
    font-weight: 800;
    letter-spacing: -1px;
    line-height: 17px;
    text-transform: uppercase;
    color: #023575;
    margin: 0;
    padding: 0 0 15px;
}
.top-contact .small-heading {
    text-align: left;
    font-size: 16px;
    line-height: 1.3;
    font-family: lato,sans-serif;
    font-weight: 500;
    color: #758495;
    display: inline-block;
    float: left;
    width: 100%;
    margin-bottom: 0px;
}

.blue-bar {
    padding: 23px 20px 0;
    font-size: 21px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: poppins,sans-serif;
    color: #fff;
    letter-spacing: 0;
    text-decoration: none;
    line-height: 1.3;
    height: 135px;
    background: #deb00a;
    border: 0;
}
.contact-form .col-md-8, .contact-form .col-md-4{
	padding: 0 !important;
}
.contactform .col-md-6, .contactform .row, .contactform .col-md-12{
	padding: 0 !important;
	margin: 0;
}

.contactform .form-group {
    border-right: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    position: relative;
    padding: 30px 30px 23px;
    margin: 0;
    font-size: 17px;
    font-weight: 700;
    color: #6c757d;
}

.contactform .form-group .form-control, .contactform .form-group .custom-select {
    width: 100%;
    border: 0;
    height: 40px;
    font-size: 17px;
    font-weight: 600;
    color: #6c757d;
    z-index: 1;
    position: relative;
    background: 0 0;
    box-shadow: none;
    vertical-align: middle;
    padding-left: 0;
}

.contactform .form-group  textarea.form-control{
	height: 180px;
	padding: 0;
}
.contactform .same-btn {
    margin: 35px;
}
.contactform .form-group .form-control, .contactform .form-group .custom-select:focus-visible{
	outline: none;
}

.right-form-address{
	background: #234890;
    color: #fff;
    border-radius: 0 0 2px 0;
    padding-bottom: 100px;
}
.OurAddress {
    padding: 60px 60px 60px;
    text-align: center;
    border-bottom: 1px solid rgba(255,255,255,.15);
}
.OurAddress:nth-child(4) {
	border-bottom: none;
}
.OurAddress .h3 {
    font-size: 25px;
    text-transform: uppercase;
    color: #fff;
    font-family: poppins,sans-serif;
    font-weight: 700;
    line-height: 1.1;
    letter-spacing: -.5px;
    margin-bottom: 15px;
}
.OurAddress p {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
    font-family: lato,sans-serif;
    line-height: 1.6;
}

.OurAddress.reviews {
    padding: 0 40px 150px;
}


.Footer {
    padding: 35px 0 70px;
}