@media (min-width: 1280px){ 
  .OurCnt{
    display: flex;
    align-items: center;
  }
}
@media (max-width: 1280px){
  .brands .list-group{
    width: 100%;
    margin: 0 auto 15px;
  }
  .GetStarted.Digipanda_Contact{
    display: block;
  }
  a.GetStarted.Digipanda_Contact{
    padding: 6px 16px 6px 45px !important;
  }
  .OurCnt {
    line-height: 0;
    display: flex;
    align-items: center;
  }
  .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl{
    padding: 0 2%;
  }
}

@media (max-width: 1199px){
  .Digipanda_Contact{
    display: none;
  }
  #App-header a.GetStarted{
    margin: 0 15px 0 0;
  }
  .navbar-light .navbar-nav .nav-item {
    margin: 0 12px;
  }
  a.CloseNavMenu{
    right: 45px;
  }
  .CaseStudy-slider .owl-nav{
    right: 0;
  }
  ul.hire-team-process li {
    padding: 0 10px;
  }
  .banner-right-content h2{
    font-size: 62px;
  }
  .Consultingback{
    background-size: auto 100%;
    background-position: 70% 0%;
  }
  .Industry_Bussness .RightImg img{
    max-width: 100%;
  }
  .HereoBanner.Webdevelopment.digipanda-portfolio .left-img img {
    max-width: 650px;
  }
  .section .right-img-sec img{
    max-width: 700px;
  }
  .Banner_Content .red-btn{
    font-size: 15px;
  }
  .HereoBanner.Webdevelopment.digipanda-portfolio4 .section .right-img-sec img{
    max-width: 1000px;
  }
  .Webdevelopment.QualityTesting .left-img img{
    max-width: 550px;
  }
  .HereoBanner.Webdevelopment {
    background-color: #f0f2f5;
    background-image: linear-gradient( 
180deg
 , #6c7d92 11%, #eee 11%);
  }
}

@media (max-width: 991px){
  a.CloseNavMenu{
    right: 25px;
  }
  .MainNavbarNav h1 {
    font-size: 96px;
    letter-spacing: -8px;
    margin: 70px auto 20px 15px;
  }
  .MainNavbarNav h1 br{
    display: none;
  }
  .HereoBanner{
    max-height: 600px;
    position: relative;
    background-size: cover;
    background-attachment: scroll;
  }
  .contact {
    background-position: 0 0;
    background-size: 100% auto;
    background-attachment: scroll;
    padding: 60px 0;
    height: 425px;
  }
  .Banner_Content h1 {
    font-size: 72px;
    line-height: 62px;
    margin-top: 40px;
    height: 125px;
    margin-bottom: 0;
    letter-spacing: -5px;
    max-width: 90%;
    left: 5px;
    position: relative;
  }
  .Banner_Content h1 br{display: none;}
  .Service_Col{
    padding: 50px 0 0;
  }
  .transform-sec h2{
    font-size: 40px;
  }
  .counter {
    font-size: 22px;
  }
  .certified-sec .sm-col h4 {
    font-size: 14px;
  }
  .certified-sec .sm-col p {
    font-size: 11px;
    line-height: 1.2;
  }
  .brands .list-group-item img {
    max-width: 70px;
  }
  .HomeServices h2{
    font-size: 36px;
  }
  .testimonial-col{
    margin-top: 50px;
  }
  .menu-listing {
    padding:35px 15px 0 15px;
  }
  .office-sec.row {
    width: 100%;
    float: left;
    display: inline-block;
  }
  .office-sec .col {
    padding: 20px;
    float: left;
    width: 33.3%;
    height: 150px;
  }
  .office-sec .col:nth-child(1) {
    width: 100%;
    padding: 10px 20px 8px;
    height: auto;
    text-align: left;
  }
  .office-sec .col:nth-child(5) {
    padding: 20px 2%;
    width: 100%;
    height: auto;
    float: left;
  }
  .office-sec .title {
    position: relative;
    top: inherit;
    transform: none;
    text-align: left;
    margin: 0;
  }
  .HereoBanner.Webdevelopment{
    position: relative;
  }
  .banner-left-img {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 600px;
    padding: 0;
    top: 155px;
  }
  .banner-right-content{
    left: 0;
    padding-left: 15px;
  }
  .heart-img {
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px 30px 0 0;
  }
  .brands.technology_brands .list-group-item img {
    max-width: 140px;
  }
  ul.hire-team-process li {
    width: 50%;
    margin: 0 auto 50px;
  }
  ul.hire-team-process li:after{
    display: none;
  }
  .guarantee-row .grnt-cols {
    width: 50%;
    min-width: 50%;
    border-bottom: 1px solid #e5e5e5;
    padding: 30px;
  }
  .guarantee-row {
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: -1px;
  }
  .guarantee-row .grnt-cols:first-child{
    padding-left: 30px;
  }
  .column-box p{
    margin-bottom: 0px;
  }
  .awards{
    margin: 35px 0;
  }
  .HereoBanner.Webdevelopment .Banner_Content{
    padding-top: 0;
  }
  .case-study-back{
    background-position: right;
  }
  .offset-1 {
    margin-left: 0;
  }
  .banner-left-img .app-dev img{
    max-width: 310px;
  }
  .banner-left-img{
    position: static;
  }
  .Webdevelopment .left-img{
    padding-top: 10%;
  }
  .HereoBanner.Webdevelopment{
    height: auto;
    min-height: 100vh;
  }
  .HereoBanner.Webdevelopment.softdev .left-img{
    max-width: 100%;
    top: 60px;
  }
  .HereoBanner.Webdevelopment {
    background-color: #f0f2f5;
    background-image: linear-gradient( 
180deg
 , #6c7d92 13%, #eee 13%);
  }
}

@media (max-width: 767px){
  .HereoBanner.Webdevelopment {
    background-color: #f0f2f5;
    background-image: linear-gradient( 
180deg
 , #6c7d92 11%, #eee 11%);
  }
  .Banner_Content h1 {
    margin: 0;
    height: auto;
    min-height: 108px;
    line-height: .9;
    font-size: 45px;
    letter-spacing: -2px;
  }
  .Banner_Content p {
    font-size: 18px;
    line-height: 22px;
  }
  .Banner_Content .red-btn{
    font-size: 15px;
  }
  .MainNavbarNav h1{
    font-size: 48px;
    letter-spacing: -3px;
    line-height: .85;
    margin-top: 0px;
    margin-left: 0px;
  }
  .office-sec.row{
    display: none;
  }
  .pTop.order2{
    padding-top: 40px;
  }
  .order2{
    order: 2;
  }
  .order1{
    order: 1;
  }
  .toggle_nav .nav-item a {
    font-size: 20px;
    padding: 8px 0;
  }
  .toggle_nav .dropdown-menu a{
    font-size: 14px;
    padding: 0 12px 2px 15px;
  }
  h2 {
    font-size: 31px !important;
    letter-spacing: -1px !important;
    line-height: 1.1 !important;
  }
  .certified-sec .sm-col:nth-child(3){
    display: none;
  }
  .certified-sec .sm-col {
    padding: 10px 0 32px;
    max-width: 50%;
    border-top: 1px solid rgba(255,255,255,.15);
  }
  .transform-sec{
    border: none;
  }
  .counter {
    font-size: 30px;
    font-weight: 700;
  }
  .brands{
    display: none;
  }
  .column-box p{
    padding: 15px 15px;
    margin: 0;
  }
  .testimonial-col .item {
    padding: 30px 15px 60px;
    background: #fff;
    height: 300px;
  }
  .CaseStudy-slider .owl-nav{
    top: -35px;
  }
  .contact .Banner_Content h1 {
    font-size: 41px;
    letter-spacing: -1px;
    text-indent: -2px;
    margin: 55px 0 0;
    min-height: 0px;
  }
  .contact .Banner_Content p {
    font-size: 18px;
    line-height: 1.4;
    padding: 3px 0 10px;
  }
  .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    padding: 0 15px;
  }
  .contact{
    height: 320px;
    background-size: auto 100%;
    background-position: 85% 0;
  }
  .contactform .form-group {
    padding: 25px 23px 15px;
    border-right: 0;
  }
  .contactform .col-md-6, .contactform .row, .contactform .col-md-12 {
    padding: 0 !important;
    margin: 0 -15px;
  }
  .top-contact {
    padding: 45px 20px 105px 25px;
  }
  .contactform .form-group .form-control, .contactform .form-group .custom-select{
    font-size: 15px;
    padding: 10px 30px;
  }
  .contactform .form-group textarea.form-control {
    height: 180px;
    padding: 10px 30px;
  }
  .contactform .same-btn {
    margin: 25px 0 40px 55px;
  }
  .heart-img {
    padding: 20px 5px 0 0;
    max-width: 110px;
  }
  .Industry_Bussness img{
    margin-top: 60px;
  }
  .Commitment_area.clearfix .text-center.col-md-12, .Commitment_area p {
    text-align: left !important;
  }
  .Commitment_area p {
    text-align: left !important;
    padding:0;
  }
  .guarantee-row .grnt-cols {
    width: 100%;
    min-width: 100%;
    border-right: 0;
    padding: 30px;
    border-bottom: 1px solid #e5e5e5;
  }
  .SuccessArea p {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.6;
  }
  .achivements .col-lg-3 {
    padding: 30px 40px 20px;
    border: 0;
    border-bottom: 1px solid #35557e;
  }
  .achivements .col-lg-3:last-child {
    border: 0;
  }
  .achivements{
    padding: 50px 0;
  }
  .Consultingback{
    background-size: auto 100%;
    background-position: 70% 0%;
  }
  .consultant-content{
    height: auto;
  }
  .left-sec {
    padding-right: 0;
    padding-left: 0;
    margin-right: 0;
    border: none;
  }
  .industries-content .inner-box{
    padding: 0 30px;
  }
  .right-sec{
    padding-left: 10px;
    border: none;
  }
  .benifits-col{
    margin-top: 50px;
  }
  .WhatsNew .whatsNew-col{
    max-width: 100%;
  }
  .WhatsNew{
    padding: 0 15px;
    margin-top: 30px;
  }
  .WhatsNew .whatsNew-col{
    margin-top: 40px;
  }
  .Webdevelopment.QualityTesting .left-img img {
    position: relative;
    left: 0;
    bottom: 0;
    max-width: 100%;
    float: left;
    right: 0;
    margin-top: 45px;
  }
  .blog-back .pagehead-title{
    padding: 20px 15px;
  }
  .Webdevelopment .left-img.app-dev{
    display: none;
  }
  .Industry_Bussness .RightImg img{
    position: static;
  }
  .dedicated-teams.row{
    margin-top: 50px;
  }
  .HereoBanner.Webdevelopment.digipanda-portfolio .Banner_Content{
    position: static;
    transform:none;
  }
  .HereoBanner.Webdevelopment.digipanda-portfolio {
    height: auto;
    min-height: auto;
    max-height: inherit;
    padding-bottom: 80px;
  }
  .HereoBanner.Webdevelopment.digipanda-portfolio .left-img img{
    max-width: 100%;
  }
  .HereoBanner.Webdevelopment.digipanda-portfolio .left-img{
    left: -10%;
  }
  .Webdevelopment .left-img {
    padding-top: 0;
    padding-left: 0;
  }
  .HereoBanner.Webdevelopment.digipanda-portfolio1{
    padding: 80px 0;
  }
  .HereoBanner.Webdevelopment.digipanda-portfolio1, .HereoBanner.Webdevelopment.digipanda-portfolio2, 
  .HereoBanner.Webdevelopment.digipanda-portfolio3, .HereoBanner.Webdevelopment.digipanda-portfolio4, 
  .HereoBanner.Webdevelopment.digipanda-portfolio5, .HereoBanner.Webdevelopment.digipanda-portfolio6{
    height: auto;
    min-height: auto;
    max-height: inherit;
  }
  .Banner_Content.section{
    position: static;
    transform: none;
  }
  .section .left-text-sec{
    padding-right: 0;
  }
  .banner-right-img, .section .right-img-sec{
    position: relative;
  }
  .HereoBanner.Webdevelopment.digipanda-portfolio2 .section .right-img-sec, 
  .HereoBanner.Webdevelopment.digipanda-portfolio3 .section .right-img-sec{
    bottom: 0;
  }
  .HereoBanner.Webdevelopment.digipanda-portfolio4 .section .right-img-sec img{
    max-width: 500px;
  }
  .Webdevelopment.QualityTesting .left-img{
    top: 40px;
  }
  .HereoBanner.Webdevelopment.webdev .left-img {
    top: 85px;
  }
}
@media (max-width: 540px){
  #App-header a.GetStarted{
    display: none;
  }
  .OurAddress {
    padding: 60px 15px 60px;
  }
}
@media (max-width: 479px){
  .menu-listing {
    padding: 35px 15px 0 15px;
    width: 100%;
    flex: inherit;
  }
  ul.hire-team-process li {
    width: 100%;  
  }
  .Banner_Content{
    top: 45%;
  }
  .HereoBanner.Webdevelopment {
    background-color: #f0f2f5;
    background-image: linear-gradient( 
180deg
 , #6c7d92 12%, #eee 12%);
  }
}
